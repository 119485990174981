<template>
  <!-- 传感器 -->
  <div class="role_index" id="roleList">
    <div class="role_content">
      <div class="role_button">
        <el-input
          placeholder="所属企业"
          style="width: 250px"
          v-model="searchForm.subjectionEnterprise"
          clearable
        ></el-input>
        <el-input
          placeholder="物联网编码"
          class="ml20"
          style="width: 250px"
          v-model="searchForm.deviceCode"
          clearable
        ></el-input>

        <el-button type="primary" class="ml30" @click="search" style="margin-top: 20px"
          >查询</el-button
        >

      </div>
      <div style="height: 20px; background: #f6f7f9"></div>
      <div style="padding: 0 20px">
        <el-button type="primary" @click="addRole" style="margin: 20px 0"
          >新增</el-button
        >
        <!-- <h4 style="padding-top: 20px; margin-bottom: 20px">角色列表</h4> -->
        <el-table border :data="tableData">
            <!-- <el-table-column
                type="selection"
                width="55">
            </el-table-column> -->
          <el-table-column prop="subjectionEnterprise" label="所属企业"></el-table-column>
          <el-table-column prop="deviceCode" label="物联网编码"></el-table-column>
          <el-table-column prop="installArea" label="安装区域"></el-table-column>
          <el-table-column prop="deviceFactory" label="使用厂家"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <el-table-column prop="name" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="editRole(scope.row)" size="small"
                >编辑</el-button
              >
              <el-popconfirm
                icon="el-icon-info"
                icon-color="red"
                @confirm="delRole(scope.row.id)"
                title="是否确认删除此设备?"
              >
                <el-button
                  slot="reference"
                  type="text"
                  style="margin-left: 12px"
                  size="small"
                  >删除</el-button
                >
              </el-popconfirm>
              <el-button type="text" style="margin-left: 12px" @click="details(scope.row)" size="small"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: right">
        <div style="margin-top: 12px; display: inline-block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog :title="dialogTitle" width="40%" :visible.sync="roleDialog">
      <el-form
        :model="form"
        ref="cdForm"
        style="width: 80%; margin: 20px auto 0"
        :rules="rules"
        label-width="95px"
      >
        <el-form-item label="所属企业" prop="enterpriseId">
          <el-select :disabled="dialogTitle == '详情'" @change="selectChange" v-model="form.enterpriseId" placeholder="请选择" style="margin-right: 14px">
            <el-option
              v-for="item in options1"
              :key="item.enterpriseId"
              :label="item.enterpriseName"
              :value="item.enterpriseId">
            </el-option>
          </el-select>
          <el-input style="width: 50%" :disabled="dialogTitle == '详情'" v-if="form.enterpriseId == 666666" v-model="form.subjectionEnterprise" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="物联网编码" prop="deviceCode">
          <el-input :disabled="dialogTitle == '详情'" v-model="form.deviceCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="安装区域" prop="installArea">
          <el-input :disabled="dialogTitle == '详情'" v-model="form.installArea" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="使用厂家" prop="deviceFactory">
            <el-select :disabled="dialogTitle == '详情'" v-model="form.deviceFactory" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!-- <el-input :disabled="dialogTitle == '详情'" v-model="form.deviceFactory" autocomplete="off"></el-input> -->
        </el-form-item>
        <el-form-item v-if="dialogTitle == '详情'" label="使用企业">
            <span class="mr10" v-for="(item, i) in userManufacturer" :key="i">{{item}}</span>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
             :disabled="dialogTitle == '详情'"
            type="textarea"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click="roleDialog = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
        userManufacturer: [],
        searchForm: {
            subjectionEnterprise: '',
            deviceCode: '',
        },
        options: [{
        value: 'FM',
        label: '飞梦'
      }, {
        value: 'JD',
        label: '竟道'
      }],
      tableData: [],
      dialogFormVisible: false,
      form: {},
      options1: [],
      roleName: "",
      dialogTitle: "新增传感器",
      roleDialog: false,
      checkedList: [],
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      rules: {
        enterpriseId: {
          required: true,
          message: "请选择所属企业",
          trigger: "change",
        },
        deviceCode: {
          required: true,
          message: "请输入物联网编码",
          trigger: "blur",
        },
        installArea: {
          required: true,
          message: "请输入安装区域",
          trigger: "blur",
        },
        deviceFactory: {
          required: true,
          message: "请选择使用厂家",
          trigger: "change",
        },
        
      },
      selectedList: [],
      data: [],
      defaultProps: {
        children: "children",
        label: "moduleName",
      },
    };
  },
  mounted() {
    this.enterprise();
    this.search();
    if (window.innerHeight) {
      var winHeight = window.innerHeight;
    } else if (document.body && document.body.clientHeight) {
      var winHeight = document.body.clientHeight;
    }
    document.getElementById("roleList").style.minHeight = winHeight - 90 + "px";
  },
  methods: {
    enterprise () {
      this.qa.datascreen3EnterpriseMapInfoQueryForPageList({pageSize:100,status:1}).then((res) => {
        console.log(res)
        res.data.push({enterpriseId: 8643, enterpriseName: '鱼河农场'});
        res.data.push({enterpriseId: 9999, enterpriseName: '一升小米'})
        res.data.push({enterpriseId: 666666, enterpriseName: '其他'});
        this.options1 = res.data;
      })
    },
    search() {
      let params = {
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,
        ...this.searchForm
      };
      this.qa.queryForPageListIot(params).then((res) => {
        if (res.respCode == "0000") {
          this.tableData = res.data;
          this.pageInfo.total = res.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    selectChange(){
      console.log(this.form.enterpriseId)
      for(let i = 0; i < this.options1.length; i++) {
        if(this.form.enterpriseId == this.options1[i].enterpriseId && this.form.enterpriseId != 666666) {
          this.form.subjectionEnterprise = this.options1[i].enterpriseName;
        }
      }
      if(this.form.enterpriseId == 666666) {
        this.form.subjectionEnterprise = ""
      }
    },
    getKeys(data, check) {
      // console.log(this.checkedList)
      // console.log(check)
    },

    addRole() {
      this.dialogTitle = "新增传感器";
      this.form = {
        subjectionEnterprise: '',
        deviceCode: '',
        installArea: '',
        deviceFactory: '',
        remark: '',
      };
      this.roleDialog = true;
    },
    editRole(row) {
      this.dialogTitle = "编辑传感器";
      this.form = {
        id: row.id,
        subjectionEnterprise: row.subjectionEnterprise,
        enterpriseId: row.enterpriseId,
        deviceCode: row.deviceCode,
        installArea: row.installArea,
        deviceFactory: row.deviceFactory,
        useEnterprise: row.useEnterprise,
        remark: row.remark,
      };
      this.roleDialog = true;
    },
    details(row) {
        this.dialogTitle = "详情";
        this.form = {
            id: row.id,
            subjectionEnterprise: row.subjectionEnterprise,
            deviceCode: row.deviceCode,
            installArea: row.installArea,
            deviceFactory: row.deviceFactory,
            useEnterprise: row.useEnterprise,
            remark: row.remark,
        };
        if(row.datascreenDeviceEnterpriseRelationPOS.length != null && row.datascreenDeviceEnterpriseRelationPOS.length> 0) {
          var arr = [];
          for(var i=0; i<row.datascreenDeviceEnterpriseRelationPOS.length;i++) {
            arr.push(row.datascreenDeviceEnterpriseRelationPOS[i].useEnterprise)
          }
          this.userManufacturer = [... new Set(arr)]
        }
        this.roleDialog = true;
    },
    delRole(id) {
      this.qa.doDeleteByIdIot({ id: id }).then((res) => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.search();
      });
    },
    add() {
      this.$refs.cdForm.validate((valid) => {
        if (valid) {
          if (this.dialogTitle == "新增传感器") {
            this.qa.doInsertIot(this.form).then((res) => {
              if (res.respCode == "0000") {
                this.$message({
                  message: "新增传感器成功！",
                  type: "success",
                });
                this.roleDialog = false;
                this.search();
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.qa.doUpdateIot(this.form).then((res) => {
              if (res.respCode == "0000") {
                this.$message({
                  message: "编辑传感器成功！",
                  type: "success",
                });
                this.roleDialog = false;
                this.search();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },

    handleSizeChange(page) {
      this.pageInfo.pageSize = page;
      this.search();
    },
    handleCurrentChange(page) {
      this.pageInfo.pageNo = page;
      this.search();
    },
  },
};
</script>

<style scoped>
/* .role_index {
      width: 100%;
      padding: 20px 24px;
  } */
.role_content {
  background: #fff;
}
.role_content > h4 {
  padding: 24px 24px 10px;
  font-size: 16px;
  color: #1d1e1f;
  font-weight: 400;
  line-height: 22px;
  border-bottom: 1px solid #efefef;
}
.role_button {
  padding: 10px 24px;
}
</style>